const footerExploreLinks: Record<string, any> = {
  invideo: {
    en: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    es: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    fr: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    id: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    it: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    pt: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Instagram Reels Maker',
          link: 'https://invideo.io/make/instagram-reels-maker/',
        },
        {
          name: 'Clipmaker',
          link: 'https://invideo.io/make/clipmaker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Birthday Video Maker',
          link: 'https://invideo.io/make/birthday-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
      ],
    },
    ru: {
      links: [
        {
          name: 'Youtube Video Editor',
          link: 'https://invideo.io/make/youtube-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Facebook Ad template',
          link: 'https://invideo.io/templates/facebook-ad-videos/',
        },
        {
          name: 'Slideshow Maker',
          link: 'https://invideo.io/make/slideshow-maker/',
        },
        {
          name: 'Video Ad Maker',
          link: 'https://invideo.io/make/ad-maker/',
        },
        {
          name: 'Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Youtube Intro Maker',
          link: 'https://invideo.io/make/youtube-intro-maker/',
        },
        {
          name: 'Facebook Video Ads Maker',
          link: 'https://invideo.io/make/facebook-video-ads/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Meme generator',
          link: 'https://invideo.io/make/meme-generator/',
        },
        {
          name: 'Promo video maker',
          link: 'https://invideo.io/make/promo-video-maker/',
        },
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
      ],
    },
    tr: {
      links: [
        {
          name: 'Youtube Video Editor',
          link: 'https://invideo.io/make/youtube-video-editor/',
        },
        {
          name: 'Invitation Maker',
          link: 'https://invideo.io/make/invitation-maker/',
        },
        {
          name: 'Intro Maker',
          link: 'https://invideo.io/make/free-intro-maker-without-watermark/',
        },
        {
          name: 'Outro Maker',
          link: 'https://invideo.io/make/outro-maker/',
        },
        {
          name: 'Facebook Ad template',
          link: 'https://invideo.io/templates/facebook-ad-videos/',
        },
        {
          name: 'Slideshow Maker',
          link: 'https://invideo.io/make/slideshow-maker/',
        },
        {
          name: 'Video Ad Maker',
          link: 'https://invideo.io/make/ad-maker/',
        },
        {
          name: 'Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Youtube Intro Maker',
          link: 'https://invideo.io/make/youtube-intro-maker/',
        },
        {
          name: 'Facebook Video Ads Maker',
          link: 'https://invideo.io/make/facebook-video-ads/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Meme generator',
          link: 'https://invideo.io/make/meme-generator/',
        },
        {
          name: 'Promo video maker',
          link: 'https://invideo.io/make/promo-video-maker/',
        },
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
      ],
    },
  },
  kizoa: {
    en: {
      links: [
        {
          name: 'Movie Maker',
          link: 'https://www.kizoa.com/Movie-Maker',
        },
        {
          name: 'SlideShow Maker',
          link: 'https://www.kizoa.com/Slideshow-Maker',
        },
        {
          name: 'Video Editor',
          link: 'https://www.kizoa.com/Video-Editor',
        },
        {
          name: '4K Video Editor Movie Maker',
          link: 'https://www.kizoa.com/4K-Video-Editor-Movie-Maker',
        },
        {
          name: 'Collage Maker',
          link: 'https://www.kizoa.com/Collage-Maker',
        },
      ],
    },
    fr: {
      links: [
        {
          name: 'Free Collage Maker',
          link: 'https://www.kizoa.fr/Creer-Collage-Montage-Photo',
        },
        {
          name: 'Free Online Movie Maker',
          link: 'https://www.kizoa.fr/creer-diaporama',
        },
        {
          name: 'Movie Maker',
          link: 'https://www.kizoa.fr/creer-film-montage-personnel',
        },
      ],
    },
    es: {
      links: [
        {
          name: 'Editar Videos',
          link: 'https://www.kizoa.es/Editar-Videos',
        },
        {
          name: 'Crear Collage Fotomontaje',
          link: 'https://www.kizoa.es/Crear-Collage-Fotomontaje',
        },
        {
          name: 'Movie Maker Online',
          link: 'https://www.kizoa.es/Movie-Maker-Online',
        },
        {
          name: 'Crear Video Montaje Edicion',
          link: 'https://www.kizoa.es/crear-video-montaje-edicion',
        },
      ],
    },
    tr: {
      links: [
        {
          name: 'Create and Download GIFs',
          link: 'https://tr.kizoa.com/hareketli-GIFler',
        },
        {
          name: 'Online 4K Video Editor',
          link: 'https://tr.kizoa.com/4K-Video-Film-Duzenleme-Programi',
        },
        {
          name: 'Collage Maker',
          link: 'https://tr.kizoa.com/kolaj-yapma',
        },
        {
          name: 'Online Movie Maker',
          link: 'https://tr.kizoa.com/slayt-gosterisi-video-yapma-programi',
        },
      ],
    },
  },
};

export { footerExploreLinks };
