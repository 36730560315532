import React, { useContext, useState } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import PricingPlan from '@components/pricing-plan';
import SuperbVideoCTA from '@components/superb-videos-cta';
import { footerExploreLinks } from '@constants/footerExplore';
import { HOMEPAGE } from '@constants/homepage';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import Footer from '@invideoio/web-shared/components/Footer';
import { HomepageResponseType } from 'src/types';
import useTranslation from 'next-translate/useTranslation';
import { StateContext } from '@store/store';
import dynamic from 'next/dynamic';

const HomePageWorkflow = dynamic(
  () =>
    import(
      /* webpackChunkName: "HomePageWorkflow" */
      '@components/workflow-v2/HomePageWorkflow'
    ),
);

const LetsConnect = dynamic(
  () =>
    import(
      /* webpackChunkName: "LetsConnect" */
      '@components/lets-connect-v2'
    ),
);

const AppPromo = dynamic(
  () =>
    import(
      /* webpackChunkName: "AppPromo" */
      '@components/app-promo'
    ),
);

const SwitchBackCards = dynamic(
  () =>
    import(
      /* webpackChunkName: "SwitchBackCards" */
      '@components/switch-back-cards/SwitchBackCards'
    ),
);

const HeroSectionV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeroSectionV2" */
      '@components/hero-section-v2'
    ),
);

const Achievements = dynamic(
  () =>
    import(
      /* webpackChunkName: "Achievements" */
      '@components/achievements'
    ),
);

const FeaturesCard = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesCard" */
      '@components/features-card'
    ),
);

interface HomePageV2Props {
  homepageState: HomepageResponseType;
}

function HomePageV2({ homepageState }: HomePageV2Props) {
  const { t, lang } = useTranslation('home');

  const [isCTAIntersected, setIsCTAIntersected] = useState(true);

  const globalState = useContext(StateContext);
  const { platform, os, browser, config, redirectionLink } = globalState;

  return (
    <React.Fragment>
      <div className={ivclass('iv-max-w-[100vw] iv-overflow-hidden')}>
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <ErrorBoundary>
            <HeroSectionV2
              browser={browser}
              heroSection={homepageState.heroSection}
              onCTAIntersection={setIsCTAIntersected}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className={ivclass('iv-max-w-[100vw]')}>
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <Achievements layoutV3 />

          {homepageState.Workflow && (
            <ErrorBoundary>
              <HomePageWorkflow
                platform={platform}
                browser={browser}
                makeWorkFlow={homepageState.Workflow}
              />
            </ErrorBoundary>
          )}

          {homepageState.feature && (
            <ErrorBoundary>
              <FeaturesCard
                platform={platform as PlatformType}
                browser={browser}
                sectionHead={homepageState.feature.sectionHead}
                card1={homepageState.feature.card1}
                card2={homepageState.feature.card2}
                card3Desktop={homepageState.featureCard3Desktop}
                card4={homepageState.feature.card4}
                card3Mobile={homepageState.featureCard3Mobile}
                title={homepageState.feature.title}
                layoutV3
              />
            </ErrorBoundary>
          )}
        </div>

        <div className={ivclass('xl:iv-mb-[-10px] lg:iv-mb-[-10px]')}>
          <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
            {homepageState.ScrollCards && (
              <ErrorBoundary>
                <SwitchBackCards
                  data={homepageState.ScrollCards}
                  browser={browser}
                />
              </ErrorBoundary>
            )}

            <ErrorBoundary>
              <PricingPlan
                platform={platform as PlatformType}
                theme="light"
                pricingTitle={{
                  plainHeading: 'The right plans,',
                  highlightedHeading: 'for the right price',
                }}
              />
            </ErrorBoundary>
          </div>
        </div>

        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <AppPromo os={os} isNotDesktop={platform !== 'desktop'} />
          {homepageState.LetsConnect && (
            <LetsConnect
              data={homepageState.LetsConnect}
              isMobile={platform === 'mobile'}
              browser={browser}
            />
          )}
        </div>
        <SuperbVideoCTA
          heading={{
            plainHeading: t(`superbVideo.heading.plainHeading`),
            highlightedHeading: t(`superbVideo.heading.highlightedHeading`),
          }}
          buttonText={t(`superbVideo.buttonText`)}
          CTAUrl={HOMEPAGE.superbVideo.CTAUrl}
        />
        <Footer
          os={os}
          platform={platform}
          socialLinks={globalState.socialLinks}
          exploreLinks={footerExploreLinks[config?.env?.siteId][lang].links}
        />
      </div>
    </React.Fragment>
  );
}

export default HomePageV2;
