import React, { Fragment, PropsWithChildren } from 'react';
import SEO from './SEO';
import { SEOType } from '../../../types';
import { generateJSONLDSchema } from '@utils/seo';

interface HomepageSEOprops {
  seo: SEOType;
  locale?: string;
  ogImage?: string;
  ogVideo?: string;
}

function HomepageSEO(props: PropsWithChildren<HomepageSEOprops>) {
  const { seo, locale, children, ogImage, ogVideo } = props;

  const { softwareApplicationSchema, pageSchema } = generateJSONLDSchema(
    seo,
    seo.CanonicalURL,
    'WebPage',
    {
      insertRating: true,
    },
  );

  return (
    <SEO
      title={seo.Title}
      description={seo.Description}
      canonicalurl={seo.CanonicalURL}
      keywords={seo.Keywords}
      ogImage={ogImage}
      ogVideo={ogVideo}
    >
      {children}

      {(seo.RobotFollow || seo.RobotIndex) && (
        <meta
          name="robots"
          content={`${seo.RobotFollow || ''}${
            seo.RobotFollow && seo.RobotIndex ? ', ' : ''
          }${seo.RobotIndex || ''}`}
        />
      )}

      <script
        key={'ld-schema-webpage'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(pageSchema) }}
      />

      <script
        key={'ld-schema-softwareapplication'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(softwareApplicationSchema),
        }}
      />

      {Array.isArray(seo.Meta) &&
        seo.Meta.map(meta => (
          <meta key={meta.id} name={meta.Name} content={meta.Content} />
        ))}

      {Array.isArray(seo.Hreflang) &&
        seo.Hreflang.map(hreflink => (
          <link
            key={hreflink.Lang}
            rel="alternate"
            hrefLang={
              hreflink.Lang === 'xdefault' ? 'x-default' : hreflink.Lang
            }
            href={hreflink.Link}
          />
        ))}

      {seo.Hreflang.length === 0 && (
        <Fragment>
          <link
            key="x-default"
            rel="alternate"
            hrefLang="x-default"
            href={seo.CanonicalURL}
          />
          <link
            key={locale || 'en-us'}
            rel="alternate"
            hrefLang={locale === 'en' ? 'en-us' : locale}
            href={seo.CanonicalURL}
          />
        </Fragment>
      )}
    </SEO>
  );
}

export default HomepageSEO;
