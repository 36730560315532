import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { useContext, useEffect, useState } from 'react';

import ToggleButton from '@components/pricing-plan/ToggleButton';
import Plan from '@invideoio/web-shared/components/PricingPlanCard';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { HOMEPAGE_PRICING } from '../../constants/homepage';
import { RedirectContext } from '../../store/store';

import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import { CHECKOUT_RELATIVE_URL, getRichFeatureText } from '@constants/pricing';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { ApiModule } from '@lib/API';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { serialisePlan } from '@utils/pricing.utils';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { SimpleHeadingType } from 'src/types';
import {
  AnalyticsProductType,
  ClickCtaType,
  ClickCtaUxSource,
} from 'src/types/analytics.types';
import { DispatchContext, StateContext } from '../../store/store';

const PlanV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "PricingPlanCardV2" */
      '@invideoio/web-shared/components/PricingPlanCardV2'
    ),
);

interface PricingPlanProps {
  platform: PlatformType;
  theme?: 'light' | 'dark';
  pricingTitle?: SimpleHeadingType;
}

const PricingPlan = (props: PricingPlanProps) => {
  const [toggleActive, setToggleActive] = useState(false);
  const billingCycle: 'monthly' | 'annually' = toggleActive
    ? 'monthly'
    : 'annually';
  const { plans, CTALink } = HOMEPAGE_PRICING;
  const { t } = useTranslation('common');
  const {
    platform,
    theme = 'dark',
    pricingTitle = {
      plainHeading: t(`pricing.title`),
      highlightedHeading: '',
    },
  } = props;
  const [sectionRef, setSectionRef] = useState<any>(null);
  const { isIntersecting } = useIntersectionObserver(sectionRef);

  const dispatch = useContext(DispatchContext);

  const globalState = useContext(StateContext);
  const [hasPlanFetched, setHasPlanFetched] = useState(false);

  useEffect(() => {
    if (isIntersecting && !hasPlanFetched) {
      let planUrl = process.env.NEXT_PUBLIC_PAYMENT_API || '';

      planUrl += '/v1/public/pricing_plans';
      ApiModule()
        .then(API =>
          API.get(planUrl, {
            params: {
              device_id: AnalyticsUtils.getDeviceId(),
            },
          }),
        )

        .then(response => {
          const newplanData = serialisePlan(
            response.data?.plans,
            globalState.planData,
            { theme },
          );

          dispatch({ type: 'SET_PLAN_DATA', payload: newplanData });

          setHasPlanFetched(true);
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: 'SET_PLAN_DATA', payload: plans });
        });
    }
  }, [isIntersecting]);

  const { handleClick } = useContext(RedirectContext);

  const handleOnCardClick = (plan: Record<string, any>) => () => {
    const planData = {
      plan: plan.name,
      duration: !toggleActive,
    };

    let url = plan.name === 'FREE' ? '/dashboard' : CHECKOUT_RELATIVE_URL;

    if (plan.name !== 'FREE') {
      url = url + `?plan=${window.btoa(JSON.stringify(planData))}`;
    }

    const planLabel = `${plan.label ?? 'na'}`;
    const uxSource = `${planLabel}_plan_button`;

    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: `${plan.label} Pricing`,
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: uxSource as ClickCtaUxSource,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.STUDIO,
      },
    });

    const pricing_url = `${process.env.NEXT_PUBLIC_STUDIO_HOST}${url}`;

    handleClick(pricing_url, { tab: 'SIGNUP' });
  };

  return (
    <section
      id="pricing"
      ref={setSectionRef}
      className={ivclass(
        'xl:iv-pt-150 iv-pt-100 sm:iv-pt-75',
        theme === 'dark' && 'xl:iv-pb-150 iv-pb-100 sm:iv-pb-75',
      )}
    >
      <div
        className={ivclass(
          'iv-container iv-mx-auto',
          'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        )}
      >
        <div
          className={ivclass(
            'iv-col-span-12',
            'iv-grid',
            'iv-text-center iv-mb-50 sm:iv-mb-40',
          )}
        >
          <TitleH2BlockGradient heading={pricingTitle} theme={theme} />
        </div>
        <div
          className={ivclass(
            'iv-text-center',
            'xl:iv-mt-75 iv-mt-50 sm:iv-mt-20',
          )}
        >
          <ToggleButton
            theme={theme}
            toggleState={toggleActive}
            toggleHandler={setToggleActive}
            toggleLeftClasses="iv-w-[100px] sm:iv-w-[75px] iv-left-0"
            toggleRightClasses="iv-w-[160px] sm:iv-w-[124px] iv-right-0"
          />
        </div>
        <div
          className={ivclass(
            'iv-grid',
            'iv-grid-cols-3 md:iv-grid-cols-1 sm:iv-grid-cols-1',
            'iv-gap-60 md:iv-gap-35 sm:iv-gap-30',
            'iv-max-w-5xl iv-mx-auto',
            'xl:iv-mt-75 iv-mt-50',
            'xl:iv-pb-4',
          )}
        >
          {hasPlanFetched &&
            Array.isArray(globalState.planData) &&
            globalState.planData.map((plan: any) => {
              if (plan.label === 'Free') {
                if (theme === 'light') {
                  plan.CTA.annually.backgroundColorClass = 'iv-bg-grey-90';
                  plan.CTA.monthly.backgroundColorClass = 'iv-bg-grey-90';
                }
              }

              const options = {
                theme,
                planName: plan.label,
                planDescription: plan.tagline,
                bannerText: plan.label === 'Business' ? 'Popular' : '',
                planPrice: plan.planPrice,
                billingCycle,
                CTA: {
                  text: plan.CTA[billingCycle]?.['text'],
                  backgroundColorClass:
                    plan.CTA[billingCycle]?.['backgroundColorClass'],
                },
                features: plan?.key_features?.map(
                  (feature: string, i: number) => {
                    const text = getRichFeatureText(feature.split(' (')[0]);
                    return {
                      highlightedText: text.prefix,
                      plainText: text.text,
                    };
                  },
                ),
                hideAppPromo: platform === 'mobile',
                appPromo: plan.appPromo,
                featuresCheckImage: plan.featuresCheckImage,
                onClick: handleOnCardClick(plan),
                platform: platform,
                onClickAppPromo: undefined,
              };

              if (theme === 'light') {
                return <PlanV2 key={plan.name} {...options} />;
              }

              return <Plan key={plan.name} {...options} />;
            })}
        </div>
      </div>
      <div className={ivclass('iv-text-center', 'xl:iv-mt-75 iv-mt-50')}>
        <span
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center',
            'iv-cursor-pointer',
            'iv-rounded-full',
            'iv-border-2',
            'iv-px-25 sm:iv-px-15',
            'iv-h-52 sm:iv-h-28',
            'iv-text-body-2xl sm:iv-text-body-m',
            'iv-font-semibold',
            'iv-transition-all iv-duration-150',
            theme === 'dark'
              ? 'iv-text-grey-0 iv-border-grey-0'
              : 'iv-text-blue iv-border-blue hover:iv-text-grey-0 hover:iv-bg-blue',
          )}
          onClick={() => {
            sendAmplitudeAnalyticEvent(AmplitudeEvents.compareFeatures);
            sendRudderStackAnalyticEvent({
              event_name: 'click_cta',
              event_properties: {
                ux_source: ClickCtaUxSource.CompareAllButton,
                type: ClickCtaType.ClickCompareAll,
                feature_utm: 'na',
                product: AnalyticsProductType.STUDIO,
              },
            });
            setTimeout(() => {
              window.open(CTALink, '_blank');
            }, 20);
          }}
        >
          {t(`pricing.sectionCTA`)}
        </span>
      </div>
    </section>
  );
};

export default PricingPlan;
