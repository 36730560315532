import { AUTH_TYPE, HOMEPAGE_HERO_SECTION } from '@constants/homepage';
import { RedirectContext, StateContext } from '@store/store';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { useContext } from 'react';
import {
  AnalyticsProductType,
  ClickCtaType,
  ClickCtaUxSource,
} from 'src/types/analytics.types';

export const useAuthFunctions = () => {
  const { goToAiSignup, handleClick } = useContext(RedirectContext);
  const { CTAUrl } = HOMEPAGE_HERO_SECTION;

  const globalState = useContext(StateContext);
  const onStudioLoginClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickLogin, {
      'Button Position': 'Studio Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopHeader,
        type: ClickCtaType.ClickLogin,
        feature_utm: 'na',
        product: AnalyticsProductType.STUDIO,
      },
    });
    handleClick(process.env.NEXT_PUBLIC_STUDIO_HOST || CTAUrl, {
      tab: AUTH_TYPE.LOGIN,
    });
  };

  const onStudioSignupClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Studio Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopHeader,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.STUDIO,
      },
    });
    handleClick(process.env.NEXT_PUBLIC_STUDIO_HOST || CTAUrl, {
      tab: AUTH_TYPE.SIGNUP,
    });
  };

  const onAILoginClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickLogin, {
      'Button Position': 'AI Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopHeader,
        type: ClickCtaType.ClickLogin,
        feature_utm: 'na',
        product: AnalyticsProductType.AI,
      },
    });

    goToAiSignup({ page: 'login' });
  };

  const onAISignupClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'AI Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopHeader,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.AI,
      },
    });

    goToAiSignup();
  };

  return {
    onStudioLoginClick,
    onStudioSignupClick,
    onAILoginClick,
    onAISignupClick,
  };
};
