import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useTranslation from 'next-translate/useTranslation';

interface ToggleButtonProps {
  toggleState: boolean;
  toggleHandler: Function;
  toggleLeftClasses?: string;
  toggleRightClasses?: string;
  theme?: 'dark' | 'light';
}

const ToggleButton = (props: ToggleButtonProps) => {
  const {
    toggleState,
    toggleHandler,
    toggleLeftClasses,
    toggleRightClasses,
    theme = 'dark',
  } = props;
  const { t } = useTranslation('common');

  return (
    <button
      onClick={() => {
        toggleHandler(!toggleState);
      }}
      className={ivclass(
        'iv-p-3',
        theme === 'light' ? 'iv-bg-grey-10' : 'iv-bg-white-10',
        'iv-rounded-full',
        'iv-text-grey-0',
        'iv-text-body-xl sm:iv-text-body-s',
        'iv-font-semibold',
      )}
    >
      <span
        className={ivclass(
          'iv-relative iv-block',
          'iv-z-0',
          'iv-pt-5 iv-pb-6 iv-px-20 sm:iv-px-15 sm:iv-py-8',
        )}
      >
        <span
          className={ivclass(
            'iv-absolute',
            'iv-h-full',
            'iv-top-0',
            'iv-bg-grey-0',
            'iv-rounded-full',
            'iv-z-[-1]',
            toggleState ? toggleLeftClasses : toggleRightClasses,
          )}
        />
        <span
          className={ivclass(
            theme === 'light'
              ? 'iv-text-grey-100'
              : toggleState && 'iv-text-grey-100',
          )}
        >
          {t(`pricing.monthly`)}
        </span>
        <span
          className={ivclass(
            'iv-pl-40 sm:iv-pl-30',
            theme === 'light'
              ? 'iv-text-grey-100'
              : !toggleState && 'iv-text-grey-100',
          )}
        >
          {t(`pricing.yearly`)}{' '}
          <span className={ivclass('iv-discount-gradient iv-pl-4')}>
            Save 50%
          </span>
        </span>
      </span>
    </button>
  );
};

export default ToggleButton;
