export enum ExperimentalFeatures {
  IV_GUIDED_ONBOARDING = 'iv-guided-onboarding',
  IV_PERSONA_SURVEY = 'iv-persona-survey',
}
export type Bucket = 'A' | 'B' | 'C' | 'D' | 'E';
export type Timestamp = string;

export type FeatureConfig = {
  [key in ExperimentalFeatures]: ExperimentJson;
};

export interface ExperimentJson {
  blacklist_accounts: number[];
  new: { [key in Bucket]: Timestamp };
  status: boolean;
  whitelisted_email_domains: string[];
  whitelisted_accounts: number[];
  whitelisted_roles: string[];
  buckets: Array<Bucket>;
}
