import React, { Fragment } from 'react';
import Head from 'next/head';

interface SEOProps {
  title: string;
  description?: string;
  canonicalurl?: string;
  keywords?: string;
  ogImage?: string;
  ogVideo?: string;
  children?: React.ReactNode;
}

function SEO(props: SEOProps) {
  const {
    children,
    title,
    canonicalurl,
    description,
    keywords,
    ogImage,
    ogVideo,
  } = props;
  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonicalurl && <link rel="canonical" href={canonicalurl} />}

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalurl} />
      <meta
        property="og:site_name"
        content="InVideo - Online Video Creator for Content and Marketing Videos"
      />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogVideo && (
        <Fragment>
          <meta property="og:video" content={ogVideo} />
          <meta property="og:video:type" content="video/mp4" />
        </Fragment>
      )}
      <meta
        property="article:publisher"
        content="https://www.facebook.com/invideo.io/"
      />
      <meta property="fb:admins" content="198297767789959" />
      <meta property="fb:app_id" content="1907729596109046" />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content="InVideo - Online Video Creator for Content and Marketing Videos"
      />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />

      {children}
    </Head>
  );
}

export default SEO;
