import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { AxiosResponse } from 'axios';
import dynamic from 'next/dynamic';
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  AUTH_COOKIE,
  LOCAL_STORAGE,
  PAGE_TYPE,
} from '@invideoio/web-shared/utils/utils.constant';
import { fetchMasterJSON, getInvite } from '../../lib/API';

import useSharedState from '@invideoio/web-shared/hooks/useSharedState';

import { TeamInfoType } from '@components/workflow/types';
import { CHECKOUT_RELATIVE_URL } from '@constants/pricing';
import {
  TeamInviteDeviceType,
  TeamInviteSourceType,
} from '@invideoio/web-shared/components/TeamSettings/TeamSettingTypes';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { AmplitudeEvents, sendAmplitudeAnalyticEvent } from '@utils/analytics';
import {
  generateRedirectionURLwithHost,
  generateRedirectionURLwithStudioHost,
} from '@utils/common.utils';
import { Cookies } from '@utils/cookie.utils';
import { isUserEligibleForExperiment } from '@utils/experiments/isUserEligibleForExperiment';
import { ExperimentalFeatures } from '@utils/experiments/types';
import { isOnboardingSurveyPending } from '@utils/experiments/utils';
import useTranslation from 'next-translate/useTranslation';
import { TYPEFORM_IDS } from '../../constants/common';
import { RELATIVE_PATH } from '../../constants/relative-path';
import { getReviewRedirectURL } from '../../pages';
import {
  closeAuthModal,
  closePersonaModal,
  closeTeamInviteModal,
  closeTeamJoinedModal,
  closeTeamSelectionModal,
  closeTypeformPersonalModal,
  openPersonaModal,
  openTeamJoinedModal,
  openTeamSelectionModal,
  openTypeformPersonalModal,
} from '../../store/actions';
import { DispatchContext, StateContext } from '../../store/store';
import {
  TypeFormPersonaResultType,
  TypeformPersonaResponseType,
} from '../../types';

const AuthModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "AuthModal" */
      '@invideoio/web-shared/components/Auth/AuthModal'
    ),
  { ssr: false, loading: () => <div>Loading...</div> },
);

const TeamInviteModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "TeamInviteModal" */
      '@invideoio/web-shared/components/TeamSettings/TeamInviteModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const InvitedUserSignupModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "InvitedUserSignupModal" */
      '@invideoio/web-shared/components/TeamSettings/InvitedUserSignup'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const InviteLinkOpeningMobileModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "InviteLinkOpeningMobileModal" */
      '@invideoio/web-shared/components/TeamSettings/Mobile/InviteLinkOpeningModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const InvalidInviteLinkModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "InvalidInviteLinkModal" */
      '@invideoio/web-shared/components/TeamSettings/InvalidInviteLinkModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const TeamSelectionModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "TeamSelectionModal" */
      '@invideoio/web-shared/components/TeamSettings/TeamSelectionModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const TeamSelectionMobileModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "TeamSelectionMobileModal" */
      '@invideoio/web-shared/components/TeamSettings/Mobile/TeamSelectionMobileModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const TeamJoinedModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "TeamJoinedModal" */
      '@invideoio/web-shared/components/TeamSettings/TeamJoined'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const TypeFormPersonaModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "TeamJoinedModal" */
      '@invideoio/web-shared/components/Persona/TypformModal'
    ),
  { loading: () => <div>Loading...</div>, ssr: false },
);

const PersonaSurveyModal = dynamic(
  () =>
    /* webpackChunkName: "PersonaSurveyModal" */
    import('@components/modal/survey/PersonaSurveyModal'),
  { loading: () => <div>Loading...</div>, ssr: false },
);

interface ModalProps {
  platform: string;
}

const Modal = (props: ModalProps) => {
  const globalState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { state } = useSharedState();
  const stateRef = useRef(state);
  stateRef.current = state;

  const { t } = useTranslation();
  const [teamInfo, setTeamInfo] = useState<null | TeamInfoType>(null);
  const [teamToken, setTeamToken] = useState<null | string>(null);
  const [teamInviteSource, setTeamInviteSource] =
    useState<TeamInviteSourceType>(TeamInviteSourceType.link);
  const recaptchaKey = process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY || '';
  const { platform } = props;
  const [teamInviteInfoModal, setTeamInviteInfoModal] = useState(false);
  const [invalidInviteLinkModal, setInvalidInviteLinkModal] = useState(false);
  const modalOpen =
    globalState.authModal.state ||
    globalState.teamInviteModal.state ||
    teamInviteInfoModal ||
    globalState.teamSelectionModal.state ||
    globalState.teamJoinedModal.state ||
    globalState.typeFormPersonalModal.state ||
    invalidInviteLinkModal;

  const [isNewUser, setisNewUser] = useState(false);

  const [inviteIntent, setInviteIntent] = useState<null | {
    intent: 'review';
    metadata: { video_id: number };
  }>(null);

  const [personaTypeform, setPersonaTypeform] = useState(
    process.env.NEXT_PUBLIC_TYPEFORM_ENABLED === 'true',
  );

  const handleError = (error: Error) => {
    console.error(error);
  };

  const handleTeamProcess = (response: AxiosResponse) => {
    const { error_code, data } = response.data;
    if (!!error_code) {
      const error = new Error();
      error.name = response.data.error_code;
      error.message = response.data.message;

      setTeamInviteInfoModal(false);
      handleRedirection();

      return handleError(error);
    }

    localStorage.setItem(
      LOCAL_STORAGE.ACCOUNT_DETAILS,
      JSON.stringify(data.account_details),
    );
    localStorage.setItem(window.btoa(LOCAL_STORAGE.USER), JSON.stringify(data));

    dispatch(openTeamJoinedModal());
    setTeamInviteInfoModal(false);
  };

  const handleRedirection = useCallback(
    (redirectUrl?: string) => {
      if (redirectUrl) {
        window.open(redirectUrl, '_self');
      } else {
        if (inviteIntent?.intent === 'review') {
          const video_id = inviteIntent.metadata.video_id;
          fetchMasterJSON(video_id.toString())
            .then((masterJSON: any) => {
              const dimension = masterJSON.dimensions[0];
              const templateId = masterJSON[dimension].template_id;
              const redirecUrl = generateRedirectionURLwithStudioHost(
                RELATIVE_PATH.studioRedirect,
              );
              const otherUrl = `/review?id=${templateId}&type=story&dimension=${dimension}&video_id=${video_id}`;
              window.open(
                `${redirecUrl}&otherUrl=${encodeURIComponent(otherUrl)}`,
                '_self',
              );
            })
            .catch(() => {
              if (globalState.redirectionLink) {
                window.open(
                  generateRedirectionURLwithHost(
                    globalState.redirectionLink,
                    stateRef.current.user,
                  ),
                  '_self',
                );
              } else {
                window.open(
                  generateRedirectionURLwithStudioHost(
                    RELATIVE_PATH.studioRedirect,
                  ),
                  '_self',
                );
              }
            });
        } else {
          if (globalState.redirectionLink) {
            const isEditorLink =
              globalState.redirectionLink.includes('/editor');
            const isEligibleForOnboardingSurvey =
              isUserEligibleForExperiment({
                experiment: ExperimentalFeatures.IV_PERSONA_SURVEY,
                userData: stateRef.current.user,
                featureConfig: globalState.featureConfig,
              }) && isOnboardingSurveyPending(stateRef.current.user);

            if (
              isOnlineVideoEditor() &&
              isEditorLink &&
              platform !== 'mobile' &&
              isEligibleForOnboardingSurvey
            ) {
              closeAuth();
              dispatch(openPersonaModal());
              return;
            }
            window.open(
              generateRedirectionURLwithHost(
                globalState.redirectionLink,
                stateRef.current.user,
              ),
              '_self',
            );
          } else if (
            platform === 'desktop' &&
            AnalyticsUtils.getPage() === PAGE_TYPE.MAKE
          ) {
            let url = '';
            const dimension = '16:9';

            url = window.location.origin;
            url += `/editor`;
            url += '?id=10456';
            url += `&dimension=${dimension}`;
            url += '&workflow=story';
            url += `&searchTerm=${globalState.editorParams?.searchTerm || ''}`;
            url += `&cacheRefresh=true`;
            url += globalState.editorParams?.tabType ? '&tabType=uploads' : '';
            url += globalState.editorParams?.categoryTray
              ? '&category=intro'
              : '';
            window.open(
              generateRedirectionURLwithHost(url, stateRef.current.user),
              '_self',
            );
          } else {
            window.open(
              generateRedirectionURLwithStudioHost(
                RELATIVE_PATH.studioRedirect,
              ),
              '_self',
            );
          }
        }
      }
    },
    [globalState.redirectionLink, globalState.featureConfig, inviteIntent],
  );

  const postSignupRedirection = async () => {
    setisNewUser(true);
    if (teamInfo && teamToken) {
      const TeamSetting = await import(
        '@invideoio/web-shared/lib/API/team-setting'
      ).then(module => module.TeamSetting);
      TeamSetting.joinTeam(teamToken)
        .then(response => {
          //A new authToken is returned after adding.
          if (response.data.error_code) {
            handleError(response.data.error_code);
            handleRedirection();
            return;
          }
          localStorage.setItem('authToken', response.data.data.token);
          handleTeamProcess(response);
        })
        .catch(error => {
          handleError(error);
          handleRedirection();
        });
      return;
    }

    const inviteData = await getInvite();
    if (inviteData) {
      const { status, name, role, inviter, invite_link, email_id } = inviteData;
      if (status) {
        dispatch(openTeamSelectionModal());
        setTeamInfo({ name, role, inviter, invite_link, email_id });
      } else {
        if (personaTypeform) {
          dispatch(openTypeformPersonalModal());
        } else {
          const shouldRedirectToOnboarding = isUserEligibleForExperiment({
            experiment: ExperimentalFeatures.IV_GUIDED_ONBOARDING,
            userData: state.user,
            featureConfig: globalState.featureConfig,
          });
          if (
            platform === 'desktop' &&
            shouldRedirectToOnboarding &&
            globalState?.redirectionLink !== CHECKOUT_RELATIVE_URL
          ) {
            handleRedirection(
              generateRedirectionURLwithHost(
                RELATIVE_PATH.newUserOnboarding,
                state.user,
              ),
            );
          } else {
            handleRedirection();
          }
        }
      }
    }

    closeAuth();
  };

  const isOnlineVideoEditor = () => {
    return window.location.pathname.includes('/make/online-video-editor');
  };

  const postLoginRedirection = () => {
    handleRedirection();
  };

  const onClickRestPasswordSuccess = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickResetPassword);
  };

  const onTeamInviteClose = () => {
    dispatch(closeTeamInviteModal());
    if (isNewUser && personaTypeform) {
      dispatch(openTypeformPersonalModal());
    } else {
      handleRedirection();
    }
  };

  const closeAuth = () => {
    dispatch(closeAuthModal());
  };

  const onSubmitTypeform = (response?: TypeformPersonaResponseType) => {
    const result: TypeFormPersonaResultType[] = response?.data?.result
      ?.typeform_answers
      ? response.data.result.typeform_answers
      : [];
    const selectedWorkIndustry = result.find(e => {
      if (
        e.field.id === TYPEFORM_IDS.WORK_INDUSTRY_ID ||
        e.field.id === TYPEFORM_IDS.USER_INDUSTRY_ID
      ) {
        return e;
      }
    });

    dispatch(closeTypeformPersonalModal());

    if (
      selectedWorkIndustry?.choices?.labels?.length === 1 &&
      !isOnlineVideoEditor()
    ) {
      const pageQuery = selectedWorkIndustry.choices.labels[0];
      window.open(`${RELATIVE_PATH.search}?pq=${pageQuery}`, '_self');
      return;
    }
    handleRedirection();
  };

  const postPersonaFormRedirection = () => {
    dispatch(closePersonaModal());
    handleRedirection();
  };

  const onInvalidInviteLinkModalClose = () => {
    setInvalidInviteLinkModal(false);
    handleRedirection();
  };

  const selectingJoiningTeam = () => {
    dispatch(openTeamJoinedModal());
    dispatch(closeTeamSelectionModal());
  };

  const selectingWorkAlone = () => {
    setTeamInfo(null);
    // dispatch(openTeamInviteModal());
    dispatch(closeTeamSelectionModal());
    window.location.replace('/workflow');
  };

  const afterTeamSelectionOnMobile = () => {
    dispatch(closeTeamSelectionModal());
    if (isNewUser && personaTypeform) {
      dispatch(openTypeformPersonalModal());
    } else {
      handleRedirection();
    }
  };

  const afterTeamJoined = () => {
    dispatch(closeTeamJoinedModal());
    if (isNewUser && personaTypeform) {
      dispatch(openTypeformPersonalModal());
    } else {
      handleRedirection();
    }
  };

  const afterViewProjects = () => {
    dispatch(closeTeamJoinedModal());
    if (isNewUser && personaTypeform) {
      dispatch(openTypeformPersonalModal());
    } else {
      const url = generateRedirectionURLwithStudioHost(
        RELATIVE_PATH.studioRedirect,
      );
      handleRedirection(`${url}&otherUrl=${encodeURIComponent('/videos')}`);
    }
  };

  const onMobileInviteLinkModalClose = () => {
    if (isNewUser && personaTypeform) {
      dispatch(openTypeformPersonalModal());
    } else {
      handleRedirection();
    }
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight = '15px';
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, [modalOpen, dispatch]);

  useEffect(() => {
    const InviteData = async (token: string) => {
      const inviteData = await getInvite(token);
      if (inviteData) {
        const {
          error_code,
          name,
          role,
          inviter,
          invite_link,
          email_id,
          intent,
          metadata,
        } = inviteData;

        if (intent === 'review' && metadata?.video_id) {
          setInviteIntent({
            intent: 'review',
            metadata: { video_id: metadata.video_id },
          });
          setPersonaTypeform(false);
        }

        setTeamInviteSource(
          !!email_id ? TeamInviteSourceType.email : TeamInviteSourceType.link,
        );
        const user = state.user;
        const redirectFallback = () => {
          if (user === null) {
            setTeamInviteInfoModal(true);
          }
          setTeamToken(token);
          setTeamInfo({
            name,
            role,
            inviter,
            invite_link,
            email_id,
            intent,
            metadata,
          });
        };
        if (error_code) {
          setInvalidInviteLinkModal(true);
        } else {
          getReviewRedirectURL(user, token)
            .then(res => {
              if (res) {
                window.open(res, '_self');
              } else {
                redirectFallback();
              }
            })
            .catch(() => {
              redirectFallback();
            });
        }
      }
    };

    const checkForTeamInvite = async () => {
      const queryParams = new URLSearchParams(window.location.search);

      const action = queryParams.get('action') || '';
      if (decodeURIComponent(action) !== btoa('team-invite')) {
        return;
      }
      if (queryParams.has('team-token')) {
        try {
          await InviteData(queryParams.get('team-token') || '');
        } catch (error) {
          console.log(error);
        }
      }
    };
    checkForTeamInvite();
  }, []);

  return (
    <Suspense>
      {teamInviteInfoModal &&
        ((platform === 'desktop' && (
          <InvitedUserSignupModal
            recaptchaKey={recaptchaKey}
            onClose={closeAuth}
            onLoginSuccess={postLoginRedirection}
            onSignUpSuccess={postSignupRedirection}
            onForgotPasswordSuccess={onClickRestPasswordSuccess}
            source={teamInviteSource}
            device={TeamInviteDeviceType.desktop}
            t={t}
          />
        )) || (
          <InviteLinkOpeningMobileModal
            t={t}
            onClose={onMobileInviteLinkModalClose}
          />
        ))}

      {invalidInviteLinkModal && (
        <InvalidInviteLinkModal t={t} onClose={onInvalidInviteLinkModalClose} />
      )}

      {globalState.teamSelectionModal.state &&
        teamInfo &&
        ((platform === 'desktop' && (
          <TeamSelectionModal
            t={t}
            teamToken={teamToken || ''}
            afterJoiningTeam={selectingJoiningTeam}
            workAlone={selectingWorkAlone}
            inviter={teamInfo?.inviter || ''}
            teamName={teamInfo.name}
            onJoinWithNewAccountClick={() => {
              window.localStorage.clear();
              window.location.replace(teamInfo?.invite_link || '');
              const authToken = Cookies.get(AUTH_COOKIE.AUTH_TOKEN);
              if (authToken) {
                Cookies.remove(AUTH_COOKIE.AUTH_TOKEN, {
                  path: '/',
                  secure: true,
                  sameSite: 'strict',
                });
              }
            }}
          />
        )) || (
          <TeamSelectionMobileModal
            t={t}
            inviter={teamInfo?.inviter || ''}
            teamName={teamInfo?.name}
            teamToken={teamToken || undefined}
            afterJoiningTeam={afterTeamSelectionOnMobile}
            workAlone={afterTeamSelectionOnMobile}
          />
        ))}

      {globalState.teamJoinedModal.state && teamInfo && (
        <TeamJoinedModal
          t={t}
          postViewProjects={afterViewProjects}
          postAction={afterTeamJoined}
          teamName={teamInfo.name}
          role={teamInfo.role}
        />
      )}

      {globalState.authModal.state && (
        <AuthModal
          t={t}
          recaptchaKey={recaptchaKey}
          initialTab={globalState.authModal.tab}
          onClose={closeAuth}
          onLoginSuccess={postLoginRedirection}
          onSignUpSuccess={postSignupRedirection}
          onForgotPasswordSuccess={onClickRestPasswordSuccess}
        />
      )}

      {globalState.typeFormPersonalModal.state && (
        <TypeFormPersonaModal
          onFormSubmit={onSubmitTypeform}
          onClose={onSubmitTypeform}
        />
      )}

      {globalState.teamInviteModal.state && (
        <TeamInviteModal t={t} closeTeamInviteModal={onTeamInviteClose} />
      )}

      {globalState.personaModal.state && (
        <PersonaSurveyModal onClose={postPersonaFormRedirection} />
      )}

      {modalOpen && (
        <div
          className={ivclass(
            'iv-fixed',
            'iv-top-0 iv-left-0 iv-z-50',
            'iv-h-full iv-w-full',
            'iv-overflow-x-hidden',
            'iv-bg-black-10 iv-backdrop-blur-md',
          )}
        />
      )}
    </Suspense>
  );
};
export default Modal;
