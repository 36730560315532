import { BaseAppType } from '@constants/common';

export function openAuthModal(data: any) {
  return {
    type: 'OPEN_AUTH_MODAL',
    payload: data,
  };
}

export function closeAuthModal() {
  return {
    type: 'CLOSE_AUTH_MODAL',
    payload: {},
  };
}

export function openTeamInviteModal() {
  return {
    type: 'OPEN_TEAM_INVITE_MODAL',
    payload: {},
  };
}

export function closeTeamInviteModal() {
  return {
    type: 'CLOSE_TEAM_INVITE_MODAL',
    payload: {},
  };
}

export function openTeamSelectionModal() {
  return {
    type: 'OPEN_TEAM_SELECTION_MODAL',
    payload: {},
  };
}

export function closeTeamSelectionModal() {
  return {
    type: 'CLOSE_TEAM_SELECTION_MODAL',
    payload: {},
  };
}

export function openTeamJoinedModal() {
  return {
    type: 'OPEN_TEAM_JOINED_MODAL',
    payload: {},
  };
}

export function closeTeamJoinedModal() {
  return {
    type: 'CLOSE_TEAM_JOINED_MODAL',
    payload: {},
  };
}

export function openTypeformPersonalModal() {
  return {
    type: 'OPEN_TYPEFORM_PERSONA_MODAL',
    payload: {},
  };
}

export function openPersonaModal() {
  return {
    type: 'OPEN_PERSONA_MODAL',
    payload: {},
  };
}

export function closePersonaModal() {
  return {
    type: 'CLOSE_PERSONA_MODAL',
    payload: {},
  };
}

export function closeTypeformPersonalModal() {
  return {
    type: 'CLOSE_TYPEFORM_PERSONA_MODAL',
    payload: {},
  };
}

export function openErrorToast(data: any) {
  return {
    type: 'OPEN_ERROR_TOAST',
    payload: data,
  };
}

export function closeErrorToast() {
  return {
    type: 'CLOSE_ERROR_TOAST',
    payload: {},
  };
}

export function setRedirectionLink(data: string) {
  return {
    type: 'SET_REDIRECTION_LINK',
    payload: data,
  };
}

export function setSocialLinks(data: any) {
  return {
    type: 'SET_SOCIAL_LINKS',
    payload: data,
  };
}

export function setEditorParams(data: any) {
  return {
    type: 'SET_EDITOR_PARAMS',
    payload: data,
  };
}

export function baseAppTypeLoaded(data: BaseAppType) {
  return {
    type: 'BASE_APP_TYPE_LOADED',
    payload: data,
  };
}
