import { IVAccordionItemItemProps } from '@invideoio/web-shared/Modules/IVAccordion/types';

type DataObject = Record<string, any>;

type BreadcrumbListItem = {
  name: string;
  url: string;
};

type LDSchemaOptions = {
  insertRating?: boolean;
  insertBreadcrumbs?: Array<BreadcrumbListItem>;
};
/**
 * Generate JSON+LD Schema
 * @param {object} data - JSON LD Data
 * @param {string} url - URL of the Page
 * @param {string} lang - Language
 * @param {('WebPage'| 'FAQPage')} type - Schema Type
 */
function generateJSONLDSchema(
  data: DataObject,
  url: string,
  type = 'WebPage',
  options?: LDSchemaOptions,
) {
  if (typeof url !== 'string') {
    throw Error(`Type of url is expected 'string' but recieved ${typeof url}`);
  }

  if (typeof data !== 'object') {
    throw Error(
      `Type of data is expected 'object' but recieved ${typeof data}`,
    );
  }

  const pageSchema: DataObject = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://invideo.io',
    name: 'InVideo Online Video Editor',
  };

  const softwareApplicationSchema: Record<string, any> = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'InVideo',
    description:
      'Stop spending thousands on video creation. Boost your video marketing with our easy-to-use, time-saving and cost-effective video templates.',
    image:
      'https://web-assets.invideo.io/common/prod/logos/purple_logo_with_text.svg',
    operatingSystem: 'Windows, MacOS, Chrome OS, Linux',
    applicationCategory: 'BrowserApplication',
    applicationSubCategory: 'Video editor',
  };

  if (data.Description) {
    softwareApplicationSchema.description = data.Description;
  }

  if (options?.insertRating) {
    softwareApplicationSchema['aggregateRating'] = {
      '@type': 'AggregateRating',
      ratingValue: 4.7,
      bestRating: 5,
      ratingCount: 530,
    };
  }

  if (options?.insertBreadcrumbs && options?.insertBreadcrumbs.length > 0) {
    pageSchema['breadcrumb'] = {
      '@type': 'BreadcrumbList',
      itemListElement: options.insertBreadcrumbs.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.name,
        item: item.url,
      })),
    };
  }

  if (type === 'WebPage') {
    pageSchema['@type'] = 'WebPage';
    pageSchema.url = url;

    pageSchema.publisher = {
      logo: {
        width: '190 px',
        height: '58 px',
        name: 'InVideo Logo',
        url: 'https://web-assets.invideo.io/common/prod/logos/purple_logo_with_text.svg',
        '@type': 'ImageObject',
      },
      name: 'InVideo',
      url: 'https://invideo.io',
      sameAs: [
        'https://www.facebook.com/invideo.io/',
        'https://twitter.com/@InVideoOfficial',
        'https://www.instagram.com/invideo.io/',
        'https://www.linkedin.com/company/invideoio',
      ],
      '@type': 'Organization',
    };

    if (data.Description && data.Title) {
      pageSchema.name = data.Title;
      pageSchema.description = data.Description;
    }
  }

  return { pageSchema, softwareApplicationSchema };
}

const parseAnswer = (description: { answer: string }[]) => {
  let answer = '';
  description.forEach(d => {
    if (answer) {
      answer += '';
    }
    answer = `<p>${d.answer}</p>`;
  });
  return answer;
};

/**
 * Generate FAQ Schema
 * @param {object} data - FAQ data
 * @returns {object} JSON+LD schema
 */
function generateJSONLDSchemaFAQ(data: IVAccordionItemItemProps[]) {
  const JSONSchema: Array<DataObject> = [];

  const faqSchema: DataObject = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
  };

  if (data && Array.isArray(data)) {
    data.forEach(d => {
      const entity = {
        '@type': 'Question',
        name: d.heading,
        acceptedAnswer: {
          '@type': 'Answer',
          text: Array.isArray(d.description)
            ? parseAnswer(d.description)
            : d.description,
        },
      };
      JSONSchema.push(entity);
    });

    faqSchema['mainEntity'] = JSONSchema;
  } else {
    return null;
  }

  return faqSchema;
}

/**
 * Generate JSON+LD Article Schema
 * @param {object} data - JSON LD Data
 * @param {string} url - URL of the Page
 * @param {string} lang - Language
 */
function generateJSONLDSchemaArticle(
  data: DataObject,
  url: string,
  lang = 'en',
) {
  const JSONSchema = [];

  if (typeof url !== 'string') {
    throw Error(`Type of url is expected 'string' but recieved ${typeof url}`);
  }

  if (typeof data !== 'object') {
    throw Error(
      `Type of data is expected 'object' but recieved ${typeof data}`,
    );
  }

  const articleSchema = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    headline: data.SEO?.Title,
    articleBody: data.SEO?.Description,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    url: url,
    ...(data.FeatureImage?.url && {
      image: {
        '@type': 'ImageObject',
        url: data.FeatureImage?.url,
      },
    }),
    inLanguage: lang,
    author: {
      '@type': 'Person',
      name: data.author?.Name || 'InVideo Team',
    },
    publisher: {
      '@type': 'Organization',
      logo: {
        width: '190 px',
        height: '58 px',
        name: 'InVideo Logo',
        url: 'https://web-assets.invideo.io/common/prod/logos/purple_logo_with_text.svg',
        '@type': 'ImageObject',
      },
      name: 'InVideo',
      url: 'https://invideo.io',
      sameAs: [
        'https://www.facebook.com/invideo.io/',
        'https://twitter.com/@InVideoOfficial',
        'https://www.instagram.com/invideo.io/',
        'https://www.linkedin.com/company/invideoio',
      ],
    },
    datePublished: data.published_at,
  };
  if (data.VideoSchema.length) {
    const embedList: Array<DataObject> = [];
    data.VideoSchema.forEach((d: DataObject) => {
      embedList.push({
        '@type': 'VideoObject',
        ...(d.Name && { name: d.Name }),
        ...(d.Description && { description: d.Description }),
        ...(d.Thumbnail?.url && { thumbnailUrl: d.Thumbnail.url }),
        ...(d.UploadDate && { uploadDate: d.UploadDate }),
        ...(d.EmbedUrl && { embedUrl: d.EmbedUrl }),
      });
    });
    articleSchema.video = embedList;
  }
  JSONSchema.push(articleSchema);

  return JSONSchema;
}

function generateJSONLDSchemaHowTo(
  schemaData: Array<DataObject>,
  url: string,
  lang = 'en',
) {
  const JSONSchema: Array<DataObject> = [];

  if (typeof url !== 'string') {
    throw Error(`Type of url is expected 'string' but recieved ${typeof url}`);
  }

  if (typeof schemaData !== 'object') {
    throw Error(
      `Type of data is expected 'object' but recieved ${typeof schemaData}`,
    );
  }

  schemaData.forEach(data => {
    const howToSchema = {
      '@context': 'https://schema.org/',
      '@type': 'HowTo',
      ...(data.Title && { name: data.Title }),
      ...(data.description && { description: data.description }),
      ...(data.Image?.url && {
        image: {
          '@type': 'ImageObject',
          url: data.Image.url,
        },
      }),
      step: [],
    };

    if (data.Step && Array.isArray(data.Step)) {
      data.Step.forEach(d => {
        const entity = {
          '@type': 'HowToStep',
          ...(d.StepUrl && { url: d.StepUrl }),
          ...(d.StepName && { name: d.StepName }),
          ...(d.StepPosition && { position: d.StepPosition }),
          ...(d.StepDescription && {
            itemListElement: {
              '@type': 'HowToDirection',
              text: d.StepDescription,
            },
          }),
          ...(d.StepImage?.url && {
            image: {
              '@type': 'ImageObject',
              url: d.StepImage.url,
            },
          }),
        };
        howToSchema.step.push(entity);
      });
    }

    JSONSchema.push(howToSchema);
  });

  return JSONSchema;
}

function getBreadcrumbsForPage(
  page?: string,
): Array<BreadcrumbListItem> | null {
  if (page === '/online-video-editor')
    return [
      {
        name: 'Home',
        url: 'https://invideo.io',
      },
      {
        name: 'Make',
        url: 'https://invideo.io/make',
      },
      {
        name: 'Online Video Editor',
        url: 'https://invideo.io/make/online-video-editor',
      },
    ];
  return null;
}

export {
  generateJSONLDSchema,
  generateJSONLDSchemaFAQ,
  generateJSONLDSchemaArticle,
  generateJSONLDSchemaHowTo,
  getBreadcrumbsForPage,
};
