import {
  Bucket,
  ExperimentalFeatures,
  FeatureConfig,
  Timestamp,
} from '@utils/experiments/types';
import { UserType } from '@invideoio/web-shared/types/Auth';

interface isUserEligibleForExperimentParams {
  userData: UserType | null;
  experiment: ExperimentalFeatures;
  featureConfig: FeatureConfig;
}

function isUserBucketEligible(
  userBucket: Bucket,
  userCreatedAt: number,
  experimentJsonNew: {
    [key in Bucket]: Timestamp;
  },
) {
  if (
    experimentJsonNew &&
    Object.keys(experimentJsonNew).length &&
    Object.keys(experimentJsonNew).includes(userBucket)
  )
    return (
      new Date(experimentJsonNew[userBucket]) < new Date(userCreatedAt * 1000)
    );

  return true;
}

export const isUserEligibleForExperiment = ({
  experiment,
  userData,
  featureConfig,
}: isUserEligibleForExperimentParams): boolean => {
  const experimentJson = featureConfig?.[experiment];

  if (!userData || !experimentJson) {
    return false;
  }

  const { bucket, account, email, created_at } = userData;
  const {
    buckets,
    blacklist_accounts,
    whitelisted_accounts,
    whitelisted_email_domains,
  } = experimentJson;

  const userBucket = bucket?.toUpperCase() as Bucket;
  const releasedBuckets = buckets?.map(bucket => bucket.toUpperCase());

  if (blacklist_accounts && account && blacklist_accounts.includes(account))
    return false;
  if (whitelisted_accounts && account && whitelisted_accounts.includes(account))
    return true;
  if (
    whitelisted_email_domains &&
    email &&
    whitelisted_email_domains.includes(email.split('@')[1])
  )
    return true;

  return (
    !!userBucket &&
    !!created_at &&
    !!account &&
    !!releasedBuckets &&
    !!Object.keys(experimentJson).length &&
    releasedBuckets.includes(userBucket) &&
    isUserBucketEligible(userBucket, created_at, experimentJson.new)
  );
};
