const exploreLinks: Record<string, any> = {
  invideo: {
    en: {
      'online-video-editor': {
        name: 'Online Video Editor',
        type: 'leaf',
        url: 'https://invideo.io/make/online-video-editor/',
      },
      'intro-maker': {
        name: 'Intro Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/free-intro-maker-without-watermark/',
      },
      'outro-maker': {
        name: 'Outro Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/outro-maker/',
      },
      'slideshow-maker': {
        name: 'Slideshow Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/slideshow-maker/',
      },
      'ad-maker': {
        name: 'Video Ad Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/ad-maker/',
      },
      'youtube-intro-maker': {
        name: 'Youtube Intro Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/youtube-intro-maker/',
      },
      'birthday-video-maker': {
        name: 'Birthday Video Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/happy-birthday-video/',
      },
      'instagram-ad-maker': {
        name: 'Instagram Video Editor',
        type: 'leaf',
        url: 'https://invideo.io/make/instagram-video-editor/',
      },
      'meme-generator': {
        name: 'Invitation Maker',
        type: 'leaf',
        url: 'https://invideo.io/make/invitation-maker/',
      },
      /*
      'view-all': {
        name: 'View all',
        type: 'leaf',
        url: 'https://invideo.io/make',
        icon: ANGLE_RIGHT_ICON_BLUE_MOBILE,
        color: 'iv-text-blue',
      },
      */
    },
  },
  kizoa: {
    en: {
      'movie-maker': {
        name: 'Movie Maker',
        type: 'leaf',
        url: 'https://www.kizoa.com/Movie-Maker',
      },
      'slideshow-maker': {
        name: 'SlideShow Maker',
        type: 'leaf',
        url: 'https://www.kizoa.com/Slideshow-Maker',
      },
      'video-editor': {
        name: 'Video Editor',
        type: 'leaf',
        url: 'https://www.kizoa.com/Video-Editor',
      },
      '4k-video-editor': {
        name: '4K Video Editor Movie Maker',
        type: 'leaf',
        url: 'https://www.kizoa.com/4K-Video-Editor-Movie-Maker',
      },
      'Collage-maker': {
        name: 'Collage Maker',
        type: 'leaf',
        url: 'https://www.kizoa.com/Collage-Maker',
      },
    },
    fr: {
      'Free Collage Maker': {
        name: 'Free Collage Maker',
        type: 'leaf',
        url: 'https://www.kizoa.fr/Creer-Collage-Montage-Photo',
      },
      'Free Online Movie Maker': {
        name: 'Free Online Movie Maker',
        type: 'leaf',
        url: 'https://www.kizoa.fr/creer-diaporama',
      },
      'Movie Maker': {
        name: 'Movie Maker',
        type: 'leaf',
        url: 'https://www.kizoa.fr/creer-film-montage-personnel',
      },
    },
    es: {
      'Editar Videos': {
        name: 'Editar Videos',
        type: 'leaf',
        url: 'https://www.kizoa.es/Editar-Videos',
      },
      'Crear Collage Fotomontaje': {
        name: 'Crear Collage Fotomontaje',
        type: 'leaf',
        url: 'https://www.kizoa.es/Crear-Collage-Fotomontaje',
      },
      'Movie Maker Online': {
        name: 'Movie Maker Online',
        type: 'leaf',
        url: 'https://www.kizoa.es/Movie-Maker-Online',
      },
      'Crear Video Montaje Edicion': {
        name: 'Crear Video Montaje Edicion',
        type: 'leaf',
        url: 'https://www.kizoa.es/crear-video-montaje-edicion',
      },
    },
    tr: {
      'Create and Download GIFs': {
        name: 'Create and Download GIFs',
        type: 'leaf',
        url: 'https://tr.kizoa.com/hareketli-GIFler',
      },
      'Online 4K Video Editor': {
        name: 'Online 4K Video Editor',
        type: 'leaf',
        url: 'https://tr.kizoa.com/4K-Video-Film-Duzenleme-Programi',
      },
      'Collage Maker': {
        name: 'Collage Maker',
        type: 'leaf',
        url: 'https://tr.kizoa.com/kolaj-yapma',
      },
      'Online Movie Maker': {
        name: 'Online Movie Maker',
        type: 'leaf',
        url: 'https://tr.kizoa.com/slayt-gosterisi-video-yapma-programi',
      },
    },
  },
};

export { exploreLinks };
