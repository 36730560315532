import React, { useContext } from 'react';
import Modal from '@components/modal';
import ChatSupportBubble from '@invideoio/web-shared/components/ChatSupportBubble';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { StateContext } from '@store/store';
import { BaseAppType } from '@constants/common';

interface AppLayoutProps {
  children: React.ReactNode;
  platform: string;
  hideChatBubble?: boolean;
}

const AppLayout = ({
  children,
  platform,
  hideChatBubble = false,
}: AppLayoutProps) => {
  const { baseAppType } = useContext(StateContext);
  const onChatBubbleLoaded = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSupport);
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.ClickSupportIcon,
        type: ClickCtaType.ClickSupport,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
  };

  return (
    <React.Fragment>
      {children}
      <Modal platform={platform} />
      {!hideChatBubble && (
        <ChatSupportBubble
          platform={platform as PlatformType}
          onChatBubbleLoaded={onChatBubbleLoaded}
        />
      )}
    </React.Fragment>
  );
};

export default AppLayout;
