import React, { useState } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import GlobalConfig from '@invideoio/web-shared/lib/GlobalConfig';
import Image from '@invideoio/web-shared/components/common/Image';
import { BrowserType } from '@invideoio/web-shared/constants/common';

interface Props {
  onClose?: () => void;
  browser?: BrowserType;
}

const StudioBanner = ({ onClose, browser = BrowserType.Chrome }: Props) => {
  const assetUrl = GlobalConfig.cdn.SHARED_URL;
  const [showBanner, setShowBanner] = useState(true);

  const onClosed = (e: React.MouseEvent) => {
    e.preventDefault(); // avoids <a/> href from being called
    setShowBanner(false);
    onClose?.();
  };

  const imageExtension = browser === BrowserType.Safari ? '.png' : '.webp';

  if (!showBanner) {
    return <></>;
  }

  return (
    <section
      className={ivclass(
        'iv-h-[67px] iv-shrink-0 iv-z-[51]',
        'sm:iv-w-full',
        'iv-transition-all iv-duration-500 iv-ease-in-out',
        'iv-block',
      )}
    >
      <a
        href={`${GlobalConfig.HOST_URL}/ai-videos`}
        className={ivclass(
          'iv-z-20 iv-fixed',
          'iv-gradient-deepblue-raspberry-red',
          'iv-w-full iv-h-[67px]',
          'iv-flex iv-flex-wrap iv-justify-center iv-items-center',
          'iv-transition-all iv-duration-500 iv-ease-in-out',
        )}
      >
        <Image
          src={`${assetUrl}/landing-pages/studio-v30-banner.png`}
          alt="studio-banner"
          className={ivclass(
            'iv-relative iv-bottom-[-5px]',
            'sm:iv-absolute sm:iv-left-[-250px]',
            'iv-w-[368px]',
          )}
          loading="eager"
          width={368}
          height={57}
        />
        <div
          className={ivclass(
            'iv-text-white-90 iv-px-[45px]',
            'md:iv-w-[340px]',
            'iv-relative sm:iv-left-[50px] sm:iv-text-body-m sm:iv-w-[320px]',
            'iv-text-center sm:iv-text-left md:iv-text-left',
          )}
        >
          invideo AI v3.0 is now live
          <div className="iv-flex iv-items-center">
            <div className="iv-flex iv-items-center">
              <strong>Check it out</strong>
              <svg
                className={ivclass(
                  'sm:iv-w-[20px] sm:iv-h-[20px]',
                  'md:iv-w-[20px] md:iv-h-[20px]',
                  'iv-w-[26px] iv-h-[26px]',
                )}
                viewBox="0 -5 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.2643 41.499C22.5808 40.7893 22.5808 39.6386 23.2643 38.9289L32.5268 29.3101L23.2643 19.6913C22.5808 18.9816 22.5808 17.8309 23.2643 17.1212C23.9477 16.4115 25.0557 16.4115 25.7391 17.1212L37.4766 29.3101L25.7391 41.499C25.0557 42.2087 23.9477 42.2087 23.2643 41.499Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <Image
          src={`${assetUrl}/header-icons/rounded-close.svg`}
          onClick={onClosed}
          width={20}
          height={20}
          alt="close"
          loading="eager"
          className={ivclass('iv-inline-block', 'iv-absolute iv-right-[11px]')}
        />
      </a>
    </section>
  );
};

export default StudioBanner;
